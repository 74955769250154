<template>
    <div class="hello">
   Data:[{{rows}}]
      
    </div>
  </template>
  
  <script>
  import { sql } from "@vercel/postgres";
  export default {
    name: 'PostGres',
    data() {
        return {
            rows:[]
        }
    },
    
    props: {
      msg: String
    },
    async asyncData( ) {
        const { rows } = await sql`SELECT * from CARTS`;
        console.log({"rows==":rows})
        this.rows = rows;
    },
    mounted() {
        const { rows } = sql`SELECT * from CARTS`;
        console.log({"rows==":rows})
        this.rows = rows;
    } 

  }
  </script>
  
 